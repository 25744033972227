import { Form, json, useParams } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { GetDocumentDetail } from "./api/localServices";
import { GetDocumentDetailInURL } from "./api/localServices";

import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { GetImageDetail } from "./api/localServices";
import { useLocation } from "react-router-dom";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import SvelteJSONEditor from "./SvelteJSONEditor";
import SaveIcon from "@mui/icons-material/Save";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import RestoreIcon from "@mui/icons-material/Restore";
import { Tooltip } from "@mui/material";
import "./document.css";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Breadcrumbs from "@mui/material/Breadcrumbs";
//import Link from "@mui/material/Link";
import documentForm from "./documentForm";
import { Viewer, Worker } from "@react-pdf-viewer/core";
import { defaultLayoutPlugin } from "@react-pdf-viewer/default-layout";
import UndoIcon from "@mui/icons-material/Undo";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import { UpdateDocumentOutput } from "./api/localServices";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import RemoveDoneIcon from "@mui/icons-material/RemoveDone";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/default-layout/lib/styles/index.css";
import Undo from "@mui/icons-material/Undo";
import DataView from "./screens/document/dataView";
import RenderHighlightAreas from "./screens/document/views/RenderHighlightAreas";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import JsonView from "./screens/document/views/jsonView";
import FormView from "./screens/document/views/formView";

function Document() {
  const documentID = useParams();
  //const documentID.id = 12795;
  const state = useLocation();
  let navigate = useNavigate();
  const [showEditor, setShowEditor] = useState(true);
  const [readOnly, setReadOnly] = useState(true);
  const [extractedDocument, setExtractedDocument] = useState(undefined);
  const [documentDetails, setDocumentDetails] = useState([]);
  const [documentImage, setDocumentImage] = useState("");
  const [currentIndex, setCurrentIndex] = useState("");
  const [prevDocument, setPrevDocument] = useState([]);
  const [nextDocument, setNextDocument] = useState([]);
  const [documents, setDocuments] = useState(state.state.documents);
  const [displayUI, setDisplayUI] = useState("none");
  const [loading, setLoading] = useState("flex");
  const [disableSaveButton, setDisableSaveButton] = useState(true);
  const [disableRestoreButton, setDisableRestoreButton] = useState(false);
  const [disablePreviousButton, setDisablePreviousButton] = useState(false);
  const [disableValidateButton, setDisableValidateButton] = useState(false);
  const [disableEditButton, setDisableEditButton] = useState(true);
  const [disableEditContent, setDisableEditContent] = useState(false);

  const [documentFormCode, setDocumentFormCode] = useState("");

  //Used for highlihting
  const [data, setData] = useState(null);
  const [areas, setAreas] = useState([]); // Use state for areas
  const changeArea = (pageIndex, width, height, x, y) => {
    setAreas([
      {
        pageIndex: pageIndex,
        height: height * 100,
        width: width * 100,
        left: x * 100,
        top: y * 100,
      },
    ]);
  };
  const defaultLayoutPluginInstance = defaultLayoutPlugin({
    sidebarTabs: (defaultTabs) => [
      // Remove the attachments tab (\`defaultTabs[2]\`)
    ],
  });
  const [content, setContent] = useState({
    json: "",
    text: undefined,
  });

  useEffect(() => {
    const fetchData = async () => {
      const URLData = await GetDocumentDetailInURL(documentID.id, "0");
      console.log(URLData);
      if (URLData) {
        console.log("VALIDATED");
      }
      const AWSData = await GetDocumentDetail(documentID.id, "4");
      setData(JSON.parse(AWSData));
    };

    fetchData();
  }, []);
  useEffect(() => {
    (async () => {
      try {
        const getDocument = await GetDocumentDetail(documentID.id, "3");

        setContent({
          json: JSON.parse(getDocument),
          text: undefined,
        });
        createForm(content.json);

        setExtractedDocument(JSON.parse(getDocument));
        setDisableEditButton(true);
      } catch (error) {}
      //const getDocument = await GetDocumentDetail(documentID.id, "3");
      const getImage = await GetImageDetail(documentID.id);

      setDocumentImage(getImage);

      setDisplayUI("flex");
      setLoading("none");

      // setData(getAWSDocument);
    })();
  }, [documentID]);
  function createForm(myObj) {
    console.log(myObj);
    Object.entries(myObj).forEach(([key, value]) => {
      console.log(key + " - " + value); // key - value
    });
  }

  useEffect(() => {
    const getdocuments = state.state.documents;

    const arrayDocuments = Array.from(getdocuments).filter(
      (item) => item.id == documentID.id
    );
    setCurrentIndex(
      getdocuments.findIndex(function (item) {
        return item.id == documentID.id;
      })
    );
    setDocumentDetails(arrayDocuments);
  }, [documentID]);

  useEffect(() => {
    setNextDocument((currentIndex + 1) % state.state.documents.length);
    setPrevDocument((currentIndex - 1) % state.state.documents.length);
  }, [currentIndex]);

  async function handleNext(event) {
    event.preventDefault();
    navigate("/document/" + documents[nextDocument].id, {
      state: { documents },
    });
    setDisplayUI("none");
    setLoading("flex");
  }

  async function handlePrev(event) {
    event.preventDefault();
    navigate("/document/" + documents[prevDocument].id, {
      state: { documents },
    });
    setDisplayUI("none");
    setLoading("flex");
  }

  let breadcrumbs = "";

  return (
    <>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "80vh" }}
        sx={{
          display: loading,
        }}
      >
        <CircularProgress color="secondary" />
      </Grid>
      <Grid
        container
        sx={{
          display: displayUI,
        }}
        style={{
          height: "85vh",
          spacing: 0,
        }}
      >
        {documentDetails.map((document, index) => {
          return (
            <>
              <Grid
                container
                direction="row"
                alignItems="center"
                justify="center"
                style={{ width: "100%", textAlign: "center" }}
              >
                <Grid item xs>
                  <Breadcrumbs
                    separator={<NavigateNextIcon fontSize="large" />}
                    aria-label="breadcrumb"
                    sx={{ ml: { xs: 1, md: 5 }, mb: 0 }}
                  >
                    <Link
                      underline="hover"
                      key="1"
                      to="/completed"
                      style={{ textDecoration: "none" }}
                    >
                      Completed Documents
                    </Link>

                    <Typography key="3" color="text.primary">
                      {document.fileName}
                    </Typography>
                  </Breadcrumbs>
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                alignItems="center"
                justify="center"
                // style={{ width: "100%", height: "100%", textAlign: "center" }}
              >
                <Grid item xs alignItems="center" justify="center">
                  {document.fileName.toLowerCase().includes("pdf") ? (
                    <RenderHighlightAreas
                      fileUrl={documentImage.url}
                      areas={areas}
                    />
                  ) : (
                    <Zoom>
                      <img
                        alt={document.fileName}
                        src={documentImage.url}
                        className="document-photo"
                      />
                    </Zoom>
                  )}
                </Grid>

                <Grid item xs>
                  <Tabs>
                    {" "}
                    <TabList>
                      {" "}
                      <Tab>Summary Fields</Tab> <Tab>Line Items</Tab>{" "}
                      <Tab disabled={disableEditContent}>Edit</Tab>{" "}
                    </TabList>
                    <TabPanel>
                      <div id="summaryfields">
                        {data?.ExpenseDocuments?.map((expenseDoc) => (
                          <>
                            <div key={expenseDoc.ExpenseIndex}>
                              <h4>
                                Page {expenseDoc.ExpenseIndex.toString()}{" "}
                              </h4>
                              <div>
                                {expenseDoc.SummaryFields?.map(
                                  (summaryField, summaryIndex) => (
                                    <div
                                      style={{
                                        cursor: "pointer",
                                        border: "1px solid rgb(170, 183, 184)",
                                        margin: "5px 0",
                                        backgroundColor: "white",
                                      }}
                                      key={summaryIndex}
                                      onClick={(event) =>
                                        changeArea(
                                          summaryField.PageNumber - 1,
                                          summaryField.ValueDetection.Geometry
                                            .BoundingBox.Width,
                                          summaryField.ValueDetection.Geometry
                                            .BoundingBox.Height,
                                          summaryField.ValueDetection.Geometry
                                            .BoundingBox.Left,
                                          summaryField.ValueDetection.Geometry
                                            .BoundingBox.Top
                                        )
                                      }
                                    >
                                      <strong>Label: </strong>{" "}
                                      {summaryField.LabelDetection?.Text ||
                                        summaryField.Type.Text}
                                      <br /> <strong>Value: </strong>{" "}
                                      {summaryField.ValueDetection?.Text ||
                                        "N/A"}
                                      <br />
                                      <strong> Confidence: </strong>{" "}
                                      {summaryField.ValueDetection?.Confidence.toString().substring(
                                        0,
                                        5
                                      ) + "%" || summaryField.Type.Confidence}
                                      <br />
                                    </div>
                                  )
                                )}
                              </div>
                            </div>
                          </>
                        ))}
                      </div>
                    </TabPanel>{" "}
                    <TabPanel>
                      <div id="lineItems">
                        {data?.ExpenseDocuments?.map((expenseDoc) => (
                          <div key={expenseDoc.ExpenseIndex}>
                            <h4>Page {expenseDoc.ExpenseIndex.toString()} </h4>
                            <div>
                              {expenseDoc.LineItemGroups?.map(
                                (lnItems, lineItemGroupIndex) =>
                                  lnItems.LineItems?.map(
                                    (lineItem, lineItemIndex) => (
                                      <div
                                        style={{
                                          border:
                                            "1px solid rgb(170, 183, 184)",
                                          margin: "5px 0",
                                          backgroundColor: "white",
                                        }}
                                        key={"row " + (lineItemIndex + 1)}
                                      >
                                        <strong>
                                          <big>Line {lineItemIndex + 1}</big>
                                        </strong>

                                        {lineItem.LineItemExpenseFields?.map(
                                          (
                                            lineItemExpenseField,
                                            lineItemExpenseFieldIndex
                                          ) => (
                                            <div
                                              style={{
                                                cursor: "pointer",
                                                border:
                                                  "1px solid rgb(170, 183, 184)",
                                                margin: "5px 0",
                                              }}
                                              onClick={(event) =>
                                                changeArea(
                                                  lineItemExpenseField.PageNumber -
                                                    1,
                                                  lineItemExpenseField
                                                    .ValueDetection.Geometry
                                                    .BoundingBox.Width,
                                                  lineItemExpenseField
                                                    .ValueDetection.Geometry
                                                    .BoundingBox.Height,
                                                  lineItemExpenseField
                                                    .ValueDetection.Geometry
                                                    .BoundingBox.Left,
                                                  lineItemExpenseField
                                                    .ValueDetection.Geometry
                                                    .BoundingBox.Top
                                                )
                                              }
                                            >
                                              <div
                                                key={lineItemExpenseFieldIndex}
                                              >
                                                <strong>Value:</strong>
                                                {
                                                  lineItemExpenseField
                                                    .ValueDetection?.Text
                                                }
                                              </div>

                                              <div>
                                                {" "}
                                                <strong>Type:</strong>{" "}
                                                {lineItemExpenseField.Type.Text}
                                              </div>
                                              <div>
                                                <strong> Confidence:</strong>{" "}
                                                {lineItemExpenseField.ValueDetection?.Confidence.toString().substring(
                                                  0,
                                                  5
                                                ) + "%"}
                                              </div>
                                            </div>
                                          )
                                        )}
                                      </div>
                                    )
                                  )
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                    </TabPanel>
                    <TabPanel>
                      <div
                        id="editItems"
                        sx={{
                          display: disableEditContent,
                        }}
                      >
                        <JsonView data={content} />
                      </div>
                    </TabPanel>
                  </Tabs>
                </Grid>
              </Grid>
              <Grid
                sx={{ flexDirection: { xs: "column", md: "row" } }}
                alignItems="center"
                justifyContent="center"
                container
              >
                <Grid item sm={6}></Grid>

                <Grid
                  md={6}
                  style={{
                    textAlign: "center",
                    backgroundColor: "yellow",
                  }}
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                ></Grid>
                <Grid
                  item
                  md={6}
                  style={{
                    backgroundColor: "yellow",
                  }}
                ></Grid>
              </Grid>
              {
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  sx={{ mb: 0, mt: 0 }}
                  spacing={2}
                >
                  <Grid item xs={2} style={{ textAlign: "center" }}>
                    <Tooltip title="Previous Document">
                      <Link underline="hover" onClick={handleNext}>
                        Prev
                      </Link>
                    </Tooltip>
                  </Grid>

                  <Grid item xs={2} style={{ textAlign: "center" }}>
                    <Tooltip title="Next Document">
                      <Link underline="hover" onClick={handlePrev}>
                        Next
                      </Link>
                    </Tooltip>
                  </Grid>
                </Grid>
              }
            </>
          );
        })}
      </Grid>
    </>
  );
}

export default Document;

/*
                 <Grid
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <Grid item>
                      <Box>
                        <div class="my-json-editor">
                          <SvelteJSONEditor
                            content={content}
                            readOnly={readOnly}
                            onChange={setContent}
                          />
                        </div>
                      </Box>
                    </Grid>
                  </Grid>
                  */
