import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import { CircularProgress } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import InputAdornment from "@mui/material/InputAdornment";
function TemplateFields(data) {
  const [summaryFieldDocuments, setSummaryFieldDocuments] = useState(data);
  useEffect(() => {
    setSummaryFieldDocuments(data);
    console.log("change requested");
  }, [data]);

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ maxWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Field</TableCell>
              <TableCell align="center">Confidence</TableCell>
              <TableCell align="right">Selected</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {summaryFieldDocuments.data.map((row) => (
              <TableRow
                key={row.id}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell align="left">
                  {" "}
                  <TextField
                    defaultValue={row.fieldName}
                    value={row.fieldName}
                    size="small"
                    color="secondary"
                  ></TextField>
                </TableCell>
                <TableCell align="center">
                  <TextField
                    id="confidence-number"
                    color="secondary"
                    defaultValue={Math.round(row.confidence)}
                    type="number"
                    variant="outlined"
                    size="small"
                    style={{ width: 100 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                  />
                </TableCell>
                <TableCell align="right">
                  {row.selected ? (
                    <Checkbox checked color="secondary" />
                  ) : (
                    <Checkbox color="secondary" />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
export default TemplateFields;
