import React, { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { GetModel } from "../../api/localServices";
import InputLabel from "@mui/material/InputLabel";
import { GetAnalyzeDocumentConfiguration } from "../../api/localServices";

function NewTemplateForm(display) {
  const [models, setModels] = useState([]);
  const [modelName, setModelName] = useState([]);
  const [modelId, setModelId] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [configList, setConfigList] = useState([]);

  const [displayLLMFields, setDisplayLLMFields] = useState("none");
  const [displayAWSFields, setDisplayAWSFields] = useState("block");
  const [displayLLMBasePrompt, setDisplayLLMBasePrompt] = useState("block");
  const [displayLLMPrompt, setDisplayLLMPrompt] = useState("none");
  const [checked, setChecked] = React.useState(true);
  const [ignoreFieldConfiguration, setIgnoreFieldConfiguration] =
    useState(false);
  const [analyzeConfigurationArray, setAnalyzeConfigurationArray] = useState(
    []
  );
  const [displayUI, setDisplayUI] = useState(display.data);
  useState(() => {
    setDisplayUI(display.data);
  }, [display]);
  const handleChange = (event) => {
    setChecked(event.target.checked);
  };
  const handleCancelClick = (event) => {
    setDisplayUI(false);
  };
  const handleIgnoreFieldCheckbox = (event) => {
    setIgnoreFieldConfiguration(event.target.checked);
    if (event.target.checked) {
      setDisplayLLMPrompt("block");
      setDisplayLLMBasePrompt("none");
    } else {
      setDisplayLLMPrompt("none");
      setDisplayLLMBasePrompt("block");
    }
  };
  useEffect(() => {
    (async () => {
      const modelList = await GetModel();
      GetAnalyzeDocumentConfiguration();
      setModels(modelList);
      setModelName(modelList[0].modelName);
      setModelId(modelList[0].id);
    })();
  }, []);
  useEffect(() => {
    (async () => {
      const anaConfig = await GetAnalyzeDocumentConfiguration();
      setAnalyzeConfigurationArray(anaConfig);
    })();
  }, []);
  useEffect(() => {
    (async () => {
      const modelItems = models.map((model) => (
        <MenuItem value={model.modelName} data-key={model.id} name={model.id}>
          {model.modelName}
        </MenuItem>
      ));
      setModelList(modelItems);
    })();
  }, [models]);
  useEffect(() => {
    (async () => {
      const anaConfigItems = analyzeConfigurationArray.map((item) => (
        <FormGroup>
          <FormControlLabel
            id={item.id}
            control={<Checkbox color="secondary" />}
            onChange={handleChange}
            label={item.configurationName}
          />
        </FormGroup>
      ));
      setConfigList(anaConfigItems);
    })();
  }, [analyzeConfigurationArray]);

  if (models.length < 1 || analyzeConfigurationArray.length < 1) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "90vh" }}
      >
        <CircularProgress color="secondary" />
      </Grid>
    );
  }
  if (displayUI) {
    return (
      <>
        <Grid container spacing={2} id="new-form-heading">
          <Grid item xs={12}>
            <h6>Add new template</h6>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={4} xs={4}>
            <FormControl>
              <TextField
                id="outlined-required"
                label="Template Name"
                color="info"
                sx={{ width: 400 }}
              />
            </FormControl>
          </Grid>
          <Grid item md={4} xs={4}>
            <FormControl>
              <InputLabel id="model-simple-select-label" color="info">
                Model
              </InputLabel>
              <Select
                labelId="model-select-label"
                id="model-select"
                label="Model"
                value={modelName}
                onChange={(e) => {
                  setModelName(e.target.value);
                  if (e.target.value.includes("AWS")) {
                    setDisplayAWSFields("block");
                    setDisplayLLMFields("none");
                  } else {
                    setDisplayAWSFields("none");
                    setDisplayLLMFields("block");
                  }
                  //  returnIndex(e.target.value);
                  //setFileType(e.target.hasOwnProperty("data-key"));
                }}
                color="info"
                sx={{ width: 400 }}
              >
                {modelList}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={4} xs={4}>
            <FormControl>
              <TextField
                type="number"
                id="outlined-required"
                label="Confidence"
                color="secondary"
                sx={{ width: 200 }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          id="LLM Fields"
          marginTop={1}
          display={displayLLMFields}
        >
          <Grid item md={4} xs={4}>
            <FormControl>
              <TextField
                // type="number"
                id="outlined-required"
                label="LLM Temperature"
                color="secondary"
                sx={{ width: 200 }}
              />
            </FormControl>
          </Grid>

          <Grid item md={4} xs={4}>
            <FormControl>
              <TextField
                type="number"
                id="outlined-required"
                label="LLM Max Token"
                color="secondary"
                sx={{ width: 200 }}
              />
            </FormControl>
          </Grid>
          <Grid item md={4} xs={4}>
            <FormControl>
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox Checked color="secondary" />}
                  label="Ignore Field Configuration"
                  checked={ignoreFieldConfiguration}
                  onChange={handleIgnoreFieldCheckbox}
                />
              </FormGroup>
            </FormControl>
          </Grid>

          <Grid item md={8} xs={8} display={displayLLMBasePrompt}>
            <FormControl>
              <TextField
                multiline
                sx={{ width: 650 }}
                type="textbox"
                rows={5}
                id="outlined-required"
                label="LLM Base Prompt"
                color="secondary"
              />
            </FormControl>
          </Grid>

          <Grid item md={8} xs={8} display={displayLLMPrompt}>
            <FormControl>
              <TextField
                multiline
                rows={5}
                sx={{ width: 650 }}
                type="textbox"
                id="outlined-required"
                label="LLM Prompt"
                color="secondary"
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          id="Aws-fields"
          marginTop={1}
          display={displayAWSFields}
        >
          <Grid item md={4} xs={4}>
            <FormControl>
              <TextField
                type="textbox"
                id="outlined-required"
                label="Search Criteria"
                defaultValue=""
                color="secondary"
                sx={{ width: 400 }}
              />
            </FormControl>
          </Grid>

          <Grid item md={4} xs={4}>
            <p>Analyze Configuration</p>
            <FormControl>{configList}</FormControl>
          </Grid>

          <Grid item md={4} xs={4}>
            <FormControl>
              <TextField
                type="textbox"
                multiline
                id="outlined-required"
                label="Template To Build JSONString"
                minRows={8}
                sx={{ width: 750 }}
                color="secondary"
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          id="ActionButtons"
          marginTop={1}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={6} md={6}>
            <button
              type="button"
              class="xrx-btn btn-primary btn-block btn-login"
              //disabled={disableButton}
              //onClick={handleClick}
            >
              <i class="xgl-log_in xglsize-24"></i>
              <span class="btn-label">Save</span>
            </button>
          </Grid>
          <Grid item xs={6} md={6}>
            <button
              type="button"
              class="xrx-btn btn-default btn-block btn-login" //disabled={disableButton}
              onClick={handleCancelClick}
            >
              <i class="xgl-log_in xglsize-24"></i>
              <span class="btn-label">Cancel</span>
            </button>
          </Grid>
        </Grid>
      </>
    );
  }
}
export default NewTemplateForm;
