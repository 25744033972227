import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import { CircularProgress } from "@mui/material";
import Box from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
function TemplateDetails(data) {
  const [templateDetails, setTemplateDetails] = useState(data.data);

  useEffect(() => {
    setTemplateDetails(data.data);
    console.log(data.data);
  }, [data]);

  if (templateDetails.length < 1) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "90vh" }}
      >
        <CircularProgress color="secondary" />
      </Grid>
    );
  }
  return (
    <>
      <h6>Template Details</h6>
      <Grid container spacing={2}>
        <Grid item md={4} xs={4}>
          <FormControl>
            <TextField
              id="outlined-required"
              label="Template Name"
              value={templateDetails.templateName}
              color="secondary"
            />
          </FormControl>
        </Grid>
        {templateDetails.model.includes("LLM") ? (
          <Grid item md={4} xs={4}>
            <FormControl>
              <TextField
                // type="number"
                id="outlined-required"
                label="LLM Temperature"
                value={templateDetails.LLMTemperature}
                color="secondary"
              />
            </FormControl>
          </Grid>
        ) : (
          ""
        )}
        {templateDetails.model.includes("LLM") ? (
          <Grid item md={4} xs={4}>
            <FormControl>
              <TextField
                type="number"
                id="outlined-required"
                label="LLM Max Token"
                value={templateDetails.llmMaxTokenOutput}
                color="secondary"
              />
            </FormControl>
          </Grid>
        ) : (
          ""
        )}
        {templateDetails.model.includes("LLM") &&
        !templateDetails.ignoreFieldConfiguration ? (
          <Grid item md={8} xs={8}>
            <FormControl>
              <TextField
                multiline
                sx={{ width: 650 }}
                type="textbox"
                id="outlined-required"
                label="LLM Base Prompt"
                value={templateDetails.llmPrompt}
                color="secondary"
              />
            </FormControl>
          </Grid>
        ) : (
          ""
        )}
        {templateDetails.model.includes("LLM") &&
        templateDetails.ignoreFieldConfiguration ? (
          <Grid item md={4} xs={4}>
            <FormControl>
              <TextField
                type="textbox"
                id="outlined-required"
                label="LLM Prompt"
                value={templateDetails.llmPrompt}
                color="secondary"
              />
            </FormControl>
          </Grid>
        ) : (
          ""
        )}
        <Grid item md={4} xs={4}>
          <FormControl>
            <TextField
              label="Model"
              color="secondary"
              value={templateDetails.model}
            ></TextField>
          </FormControl>
        </Grid>
        {templateDetails.model.includes("AWS") ? (
          <Grid item md={4} xs={4}>
            <FormControl>
              <TextField
                type="textbox"
                id="outlined-required"
                label="Search Criteria"
                defaultValue=""
                value={
                  templateDetails.searchCriteria
                    ? templateDetails.searchCriteria
                    : ""
                }
                color="secondary"
              />
            </FormControl>
          </Grid>
        ) : (
          ""
        )}
        <Grid item md={4} xs={4}>
          <FormControl>
            <TextField
              type="textbox"
              id="outlined-required"
              label="Confidence"
              value="90"
              color="secondary"
            />
          </FormControl>
        </Grid>
        <Grid item md={4} xs={4}>
          <FormControl>
            {templateDetails.ignoreFieldConfiguration ? (
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox Checked color="secondary" />}
                  label="Ignore Field Configuration"
                />
              </FormGroup>
            ) : (
              <FormGroup>
                <FormControlLabel
                  control={<Checkbox color="secondary" />}
                  label="Ignore Field Configuration"
                  color="secondary"
                />
              </FormGroup>
            )}
          </FormControl>
        </Grid>

        {templateDetails.model.includes("AWS") ? (
          <Grid item md={4} xs={4}>
            <FormControl>AnalyzeConfiguration</FormControl>
          </Grid>
        ) : (
          ""
        )}
        {templateDetails.model.includes("AWS") ? (
          <Grid item md={6} xs={6}>
            <FormControl>
              <TextField
                type="textbox"
                id="outlined-required"
                label="Template To Build JSONString"
                multiline
                maxRows={8}
                sx={{ width: 750 }}
                value={
                  templateDetails.templateToBuildJSONString
                    ? templateDetails.templateToBuildJSONString
                    : ""
                }
                color="secondary"
              />
            </FormControl>
          </Grid>
        ) : (
          ""
        )}
      </Grid>
    </>
  );
}
export default TemplateDetails;
