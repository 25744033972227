import React, { useState, useEffect } from "react";
import { FieldsByTemplate } from "./api/localServices";

import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import Checkbox from "@mui/material/Checkbox";
import { GetDocumentTypeTemplateByCompany } from "./api/localServices";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { GetDocumentTypeTemplateById } from "./api/localServices";
import TemplateDetails from "./screens/document/views/templateDetails";
import TemplateFields from "./screens/document/views/templateFields";
import LineFields from "./screens/document/views/lineFields";
import { Button } from "@mui/material";
import NewTemplateForm from "./screens/forms/newTemplateForm";
function Setup() {
  const [templates, setTemplates] = useState([]);
  const [fieldsByTemplate, setFieldsByTemplate] = useState([]);
  const [summaryFieldDocuments, setSummaryFieldDocuments] = useState([]);
  const [lineItemsdDocuments, setLineItemsdDocuments] = useState([]);
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [templateType, setTemplateType] = useState(1);
  const [templateTypeName, setTemplateTypeName] = useState("");
  const [templateTypesList, setTemplateTypesList] = useState("");
  const [templateTypes, setTemplateTypes] = useState([]);
  const [templateDetails, setTemplateDetails] = useState([]);
  const [displayNewTemplateForm, setDisplayNewTemplateForm] = useState("none");

  function filterByParentName(documents, parentName) {
    return documents.filter((document) => document.parentName === parentName);
  }

  function returnIndex(templateTypeName) {
    const index = templates.find((o) => o.templateName === templateTypeName);
    setTemplateType(index.id);
  }
  useEffect(() => {
    console.log(summaryFieldDocuments);
  }, [templateType]);
  useEffect(() => {}, [summaryFieldDocuments]);

  useEffect(() => {
    document.title = "Setup Documents";
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      let Tdetails = await GetDocumentTypeTemplateById(templateType);
      setTemplateDetails(Tdetails);
    };
    fetchData();
  }, [templateType]);

  useEffect(() => {
    const listItems = templates.map((template) => (
      <MenuItem
        value={template.templateName}
        data-key={template.id}
        name={template.id}
      >
        {template.templateName}
      </MenuItem>
    ));
    setTemplateTypesList(listItems);
  }, [templates]);

  useEffect(() => {
    const fetchData = async () => {
      const URLData = await FieldsByTemplate(templateType);
      let SummaryDocuments = filterByParentName(URLData, "SummaryFields");
      setSummaryFieldDocuments(SummaryDocuments);
      let LineDocuments = filterByParentName(URLData, "LineItems");
      setLineItemsdDocuments(LineDocuments);
    };

    fetchData();
  }, [templateType]);

  useEffect(() => {
    const fetchData = async () => {
      const availableTemplates = await GetDocumentTypeTemplateByCompany();
      setTemplates(availableTemplates);
      setTemplateTypeName(availableTemplates[0].templateName);
      setTemplateType(availableTemplates[0].id);
    };
    fetchData();
  }, []);

  const [formState, setFormState] = useState(templateDetails);

  const handleChange = (event) => {
    setFormState({
      ...formState,
      [event.target.name]: event.target.value,
    });
  };
  const handleAddNew = (e) => {
    e.preventDefault();
    setDisplayNewTemplateForm("block");
  };

  const addNewSummary = () => {
    summaryFieldDocuments.unshift({
      id: 0,
      documentTypeTemplateId: 1,
      selected: false,
      fieldName: "",
      confidence: 90,
      dataTypeName: "String",
      parentName: "SummaryFields",
    });
  };
  if (templates.length < 1 && templateDetails) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "90vh" }}
      >
        <CircularProgress color="secondary" />
      </Grid>
    );
  }

  return (
    <>
      {" "}
      <Grid
        container
        spacing={0}
        direction="column"
        //alignItems="center"
        justifyContent="start"
        style={{ minHeight: "90vh", padding: "5vh", paddingTop: "1vh" }}
      >
        <h4> Setup Documents</h4>
        <Grid container>
          <Grid item xs="12">
            <h6>Available Templates</h6>
          </Grid>
          <Grid item xs="4">
            {" "}
            <FormControl sx={{ m: 1, minWidth: 160, height: 60 }} color="info">
              <Select
                labelId="filetype-simple-select-label"
                id="filetype-simple-select"
                value={templateTypeName}
                onChange={(e) => {
                  setTemplateTypeName(e.target.value);
                  returnIndex(e.target.value);
                  //setFileType(e.target.hasOwnProperty("data-key"));
                }}
                color="info"
                sx={{ width: 200 }}
              >
                {templateTypesList}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs="2">
            <Button
              variant="outlined"
              color="info"
              class="xrx-btn btn-default btn-block"
              component="label"
              sx={{ ml: 2, mr: 2, maxWidth: 200 }}
              onClick={handleAddNew}
            >
              + new template
            </Button>
          </Grid>
          <Grid item xs="12" display={displayNewTemplateForm}>
            <NewTemplateForm data={true} />
          </Grid>
          <Grid item xs="12">
            <TemplateDetails data={templateDetails} />
          </Grid>

          <Grid item xs="12">
            <br></br>
          </Grid>
        </Grid>

        {templateDetails.model.includes("AWS") ? (
          <Grid container spacing={4}>
            <Grid item md="6">
              <Grid container>
                <Grid item xs="6">
                  <h6>Summary Fields</h6>
                </Grid>
                <Grid item xs="4"></Grid>
                <Grid item xs="2">
                  <Button
                    variant="outlined"
                    color="info"
                    class="xrx-btn btn-default btn-block"
                    component="label"
                    onClick={addNewSummary}
                  >
                    + new
                  </Button>
                </Grid>
                <Grid item xs="12">
                  {" "}
                  <Box>
                    <TemplateFields data={summaryFieldDocuments} />
                  </Box>
                </Grid>
              </Grid>
            </Grid>

            <Grid item md="6">
              <Grid container>
                <Grid item xs="6">
                  <h6>Line Item</h6>
                </Grid>
                <Grid item xs="4"></Grid>
                <Grid item xs="2">
                  <Button
                    variant="outlined"
                    color="info"
                    class="xrx-btn btn-default btn-block"
                    component="label"
                  >
                    + new
                  </Button>
                </Grid>
                <Grid item xs="12">
                  {" "}
                  <Box>
                    <TemplateFields data={lineItemsdDocuments} />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          ""
        )}
      </Grid>
    </>
  );
}
export default Setup;
