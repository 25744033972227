import { createTheme } from "@mui/material/styles";

function customTheme() {
  const theme = createTheme({
    typography: {
      fontFamily: "Roboto Condensed",
    },
    palette: {
      primary: {
        light: "#ffffff",
        main: "#fff",
        dark: "#cccccc",
        contrastText: "#000",
      },
      secondary: {
        light: "#3d58c1",
        main: "#3d58c1",
        dark: "#111f64",
        contrastText: "#fff",
      },
      neutral: {
        main: "#f2f2f9",
      },
      succes: {
        main: "#24882b",
      },
      error: {
        main: "#d92231",
      },
      info: {
        main: "#3d58c1",
      },
    },
  });
  return theme;
}

export default customTheme;
