import "./App.css";
import "@fontsource/roboto-condensed";
import { Outlet } from "react-router-dom";
import React, { useEffect } from "react";
import NavBar from "./navbar";
import { ThemeProvider } from "@mui/material/styles";
import Login from "./login";
import customTheme from "./customTheme";
import MainButton from "./mainButton";
//import jwt_decode from "jwt-decode";
import { logout } from "./api/auth";
import XNavBar from "./xnavbar";
import { jwtDecode } from "jwt-decode";
import "./fonts/xGlyph-Regular.woff";
import "./fonts/xGlyph-Regular.ttf";
import "./fonts/xGlyph-Regular.otf";
import { RefreshToken } from "./api/auth";
function App() {
  let theme = customTheme();

  useEffect(() => {
    document.title = "RobotApp";
  });
  useEffect(() => {
    (async () => {
      if (sessionStorage.token) {
        const decodedJwt = jwtDecode(sessionStorage.access_token);
        if (decodedJwt.exp * 1000 < Date.now()) {
          const rft = await RefreshToken();
          if (rft.status === 200) {
          } else {
            logout();
            return <Login />;
          }
        }
      }
    })();
  }, []);
  if (!sessionStorage.token) {
    return <Login />;
  }

  return (
    <>
      <ThemeProvider theme={theme}>
        <nav>
          <NavBar />
        </nav>
        <div className="main-content">
          <Outlet />
        </div>
        <div className="main-btn"></div>
      </ThemeProvider>
    </>
  );
}

export default App;
