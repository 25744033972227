import React, { useState, useEffect } from "react";
import Paper from "@mui/material/Paper";
import {
  Chart,
  PieSeries,
  Legend,
} from "@devexpress/dx-react-chart-material-ui";
import { Palette } from "@devexpress/dx-react-chart";
import { Tooltip } from "@devexpress/dx-react-chart-material-ui";
import { Animation } from "@devexpress/dx-react-chart";
import { userInformation } from "./api/localServices";
import { AvailableCredit } from "./api/localServices";
import { QtyProcessedDocuments } from "./api/localServices";
import {
  ArgumentAxis,
  ValueAxis,
  BarSeries,
  Title,
} from "@devexpress/dx-react-chart-material-ui";

import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ArticleIcon from "@mui/icons-material/Article";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";

import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import PaidIcon from "@mui/icons-material/Paid";
import Stack from "@mui/material/Stack";
import TaskIcon from "@mui/icons-material/Task";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";

import { GetDocuments } from "./api/localServices";
import { GetDocumentsPending } from "./api/localServices";
import CircularProgress from "@mui/material/CircularProgress";
import { Link } from "react-router-dom";
import "./dashboard.css";
var _ = require("lodash");

function Dashboard() {
  const [user, setUser] = useState([]);
  const [credit, setCredit] = useState([]);
  const [qtyProcessedDocs, setQtyProcessedDocs] = useState();

  useEffect(() => {
    (async () => {
      const currentUser = await userInformation();
      setUser(currentUser);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const availableCredit = await AvailableCredit();
      setCredit(availableCredit[0]);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const qtyDocs = await QtyProcessedDocuments();

      setQtyProcessedDocs(qtyDocs);
    })();
  }, []);

  const [documents, setDocuments] = useState([]);
  useEffect(() => {
    (async () => {
      const getdocuments = await GetDocuments();
      const arrayDocuments = Array.from(getdocuments);
      setDocuments(arrayDocuments.length);
      //console.log(arrayDocuments);
      var filterDate = new Date();

      filterDate.setMonth(filterDate.getMonth() - 10);
      const filteredArray = arrayDocuments.filter((document) => {
        let date = new Date(document.dateCreated).getTime();

        return date >= filterDate;
      });
      /* const modifiedArray = _.groupBy(filteredArray, ({ dateCreated }) =>
        new Date(dateCreated).getMonth()
      );
      console.log(modifiedArray);
      */
    })();
  }, []);

  const [pending, setPending] = useState("0");

  useEffect(() => {
    (async () => {
      const getpending = await GetDocumentsPending();
      const arrayPending = Array.from(getpending);
      const date = new Date();

      const month = date.getMonth() + 1;
      const year = date.getFullYear();
      const arrayPendingFiltered = arrayPending.filter(
        (f) => f.dateUpdated.includes(month) && f.dateUpdated.includes(year)
      );
      setPending(arrayPendingFiltered.length);
    })();
  }, []);

  if (!qtyProcessedDocs) {
    return (
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: "90vh" }}
      >
        <h2>Welcome, {user.names}</h2>
        <CircularProgress color="secondary" />
      </Grid>
    );
  }

  return (
    <>
      <Grid
        container
        spacing={0}
        direction="column"
        //alignItems="center"
        justifyContent="start"
        style={{ minHeight: "90vh", padding: "5vh", paddingTop: "1vh" }}
      >
        <h4>Welcome, {user.names}</h4>
        <Grid container spacing={4}>
          <Grid item md={6} xs={6}>
            <div class="dashboard-card">
              <Link to="/completed" style={{ textDecoration: "none" }}>
                <div class="xrx-card ">
                  <div class="card-1line-title">
                    <h5> {documents}</h5>
                    <div class="right-object">
                      <i class="xgl-file xglsize-32"></i>
                    </div>
                  </div>
                  <div class="card-body pt-0"> Completed Documents</div>
                </div>
              </Link>
            </div>
          </Grid>
          <Grid item md={6} xs={6}>
            <div class="dashboard-card">
              <Link to="/pending" style={{ textDecoration: "none" }}>
                <div class="xrx-card dashboard-card">
                  <div class="card-1line-title">
                    <h5> {pending}</h5>
                    <div class="right-object">
                      <i class="xgl-hourglass xglsize-32"></i>
                    </div>
                  </div>
                  <div class="card-body pt-0"> Pending Documents</div>
                </div>
              </Link>
            </div>
          </Grid>

          <Grid item md={2} xs={1}></Grid>
          <Grid item md={8} xs={12}>
            <Card sx={{ minWidth: 275 }} variant="outlined">
              <Typography m={2}>Processed Files</Typography>

              <Paper>
                <Chart data={qtyProcessedDocs} height="250">
                  <Legend />
                  <PieSeries
                    valueField="totalRecords"
                    argumentField="documentTypeName"
                    innerRadius={0.6}
                  />
                  <Palette scheme={4} />

                  <Tooltip />
                  <Animation />
                </Chart>
              </Paper>
            </Card>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

export default Dashboard;

/*
   <Grid item md={4} xs={6}>
            <div class="xrx-card">
              <div class="card-1line-title">
                <h5> {credit.totalCredits - credit.totalCreditUserDocument}</h5>
                <div class="right-object">
                  <i class="xgl-cost xglsize-32"></i>
                </div>
              </div>
              <div class="card-body pt-0">Credits Available</div>
            </div>
          </Grid>

      */
